
import { Component, Vue } from "vue-property-decorator";
import { closeWebView } from "@/service/libs/index";

@Component({
  name: "DrawMoneyInfo",
  components: {
    // HelloWorld,
    // Field,
  },
})
export default class PerfectSuccess extends Vue {
  private async handleBuy() {
    closeWebView();
  }
  private orderInfo: any = {};
  // 查询提款订单详情
  private getOrderInfo() {
    let order: string | null = localStorage.getItem("draw-order-info");
    if (order) {
      this.orderInfo = JSON.parse(order);
    }
  }
  private getExactTime(time: number) {
    var date = new Date(time * 1000);
    var year = date.getFullYear();
    var month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var dates = date.getDate() + "";
    var hour = date.getHours() + ":";
    var min = date.getMinutes() + ":";
    var second = date.getSeconds();
    return dates + "/" + month + "/" + year + " " + hour + min + second;
  }
  mounted() {
    setTimeout(() => {
      this.getOrderInfo();
    }, 100);
  }
}
